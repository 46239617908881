// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agile-team-js": () => import("./../../src/pages/agile-team.js" /* webpackChunkName: "component---src-pages-agile-team-js" */),
  "component---src-pages-blogs-en-english-blog-title-js": () => import("./../../src/pages/blogs/en/[englishBlogTitle].js" /* webpackChunkName: "component---src-pages-blogs-en-english-blog-title-js" */),
  "component---src-pages-blogs-es-spanish-blog-title-js": () => import("./../../src/pages/blogs/es/[spanishBlogTitle].js" /* webpackChunkName: "component---src-pages-blogs-es-spanish-blog-title-js" */),
  "component---src-pages-contact-us-js": () => import("./../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-exceptional-careers-js": () => import("./../../src/pages/exceptional-careers.js" /* webpackChunkName: "component---src-pages-exceptional-careers-js" */),
  "component---src-pages-exclusive-products-js": () => import("./../../src/pages/exclusive-products.js" /* webpackChunkName: "component---src-pages-exclusive-products-js" */),
  "component---src-pages-exsis-academy-js": () => import("./../../src/pages/exsis-academy.js" /* webpackChunkName: "component---src-pages-exsis-academy-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-blog-js": () => import("./../../src/pages/media-blog.js" /* webpackChunkName: "component---src-pages-media-blog-js" */),
  "component---src-pages-open-position-js": () => import("./../../src/pages/open-position.js" /* webpackChunkName: "component---src-pages-open-position-js" */),
  "component---src-pages-open-success-storie-js": () => import("./../../src/pages/open-success-storie.js" /* webpackChunkName: "component---src-pages-open-success-storie-js" */),
  "component---src-pages-our-experience-js": () => import("./../../src/pages/our-Experience.js" /* webpackChunkName: "component---src-pages-our-experience-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-expansion-js": () => import("./../../src/pages/service-expansion.js" /* webpackChunkName: "component---src-pages-service-expansion-js" */),
  "component---src-pages-services-blockchain-js": () => import("./../../src/pages/services-blockchain.js" /* webpackChunkName: "component---src-pages-services-blockchain-js" */),
  "component---src-pages-services-hunting-js": () => import("./../../src/pages/services-hunting.js" /* webpackChunkName: "component---src-pages-services-hunting-js" */),
  "component---src-pages-services-software-js": () => import("./../../src/pages/services-software.js" /* webpackChunkName: "component---src-pages-services-software-js" */),
  "component---src-pages-talent-ats-js": () => import("./../../src/pages/TalentATS.js" /* webpackChunkName: "component---src-pages-talent-ats-js" */),
  "component---src-pages-transformation-js": () => import("./../../src/pages/transformation.js" /* webpackChunkName: "component---src-pages-transformation-js" */),
  "component---src-pages-unsuscribe-js": () => import("./../../src/pages/unsuscribe.js" /* webpackChunkName: "component---src-pages-unsuscribe-js" */),
  "component---src-pages-x-media-podcast-js": () => import("./../../src/pages/x-media-podcast.js" /* webpackChunkName: "component---src-pages-x-media-podcast-js" */),
  "component---src-pages-x-media-success-stories-js": () => import("./../../src/pages/x-media-success-stories.js" /* webpackChunkName: "component---src-pages-x-media-success-stories-js" */)
}

